<template>
  <a-select :disabled="disabled" style="width: 100%" v-model="unitCode" @change="handleChange">
    <a-select-option v-for="(item, index) in unitList" :key="index" :value="item.unitNameFull">
      {{ item.unitNameFull }}
    </a-select-option>
  </a-select>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
    },
    list: {},
  },
  watch: {
    code(newVal) {
      // console.log('newVal: ', newVal)
      this.setDefault(newVal)
    },
    list: {
      immediate: true,
      handler(newVal) {
        // console.log('newVal: ', newVal)
        if (newVal?.length > 0) {
          this.unitList = newVal
          this.showDefault(newVal)
        }
      },
    },
  },
  data() {
    return {
      unitCode: undefined,
      unitList: [],
    }
  },
  mounted() {
    this.setDefault(this.code)
  },
  methods: {
    setDefault(newVal) {
      if (newVal && newVal !== 'undefined') {
        this.unitCode = newVal
      } else {
        this.unitCode = undefined
      }
    },
    showDefault(list) {
      if (list?.length === 1) {
        this.handleChange(list[0].unitNameFull)
      }
    },
    handleChange(value) {
      this.unitCode = value
      this.$emit('update:code', value)
      let data = this.unitList.find((item) => item.unitNameFull === value)
      this.$emit('change', data)
    },
  },
}
</script>
